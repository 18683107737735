import React, { useState, useEffect } from 'react';
 import Header from './Components/navbar/navbarcomp.jsx';
import './App.css';
 const App = () => {
   const [isDarkMode, setDarkMode] = useState(false);

   const handleDarkModeToggle = () => {
     setDarkMode(!isDarkMode);
   };
   useEffect(() => {
     document.body.style.backgroundColor = isDarkMode ? 'black' : 'white';

     return () => {
       document.body.style.backgroundColor = ''; 
     };
   }, [isDarkMode]);

   

   return (
     <main className={isDarkMode ? 'dark-mode' : 'light-mode'}>
       <Header onDarkModeToggle={handleDarkModeToggle} />
     </main>
   );
 };

 export default App;